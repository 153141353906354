<div class="whole-page parent bg-image bg">
    <!-- 
    <mat-toolbar>
        <span>{{title}}</span>
    </mat-toolbar>
    -->

    <div class="center border-round font-monospace" [style.background]="bgColor">
        <div class="pl-5 pr-5 pt-5 pb-5 container">
            <div class="row">
                <div class="col-sm">
                    <img class="align-right" style="max-height: 400px" src="assets/me.png">
                </div>
                <div class="col-sm ps-5 pt-5 text-light">
                    <div class="pt-5">
                        <p class="fs-1">Tyler Miller</p>
                        <p>&nbsp;</p>
                        <div class="ps-1">
                            <p class="pt-3 fs-4">Tech Lead Software Engineer at AMEX</p>
                            <p class="pt-2">Microservice Architecture</p>
                            <p class="pt-2">Observability</p>
                            <p class="pt-2">Docker and Kubernetes</p>
                            <p class="pt-2">Java, Golang</p>
                            <br>
                            <a href="mailto: millertyl0616@gmail.com" class="mr-4"><fa-icon [icon]="faEnvelope" size="2x"></fa-icon></a>
                            <a href="https://www.linkedin.com/in/tyler-miller-192559141/" class="mr-4"><fa-icon [icon]="faLinkedIn" size="2x"></fa-icon></a>
                            <a href="https://github.com/tatsu22" class="mr-4"><fa-icon [icon]="faGithub" size="2x"></fa-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
